<template>
    <div class="cont-cla">        
        <div class="search-list">
            <div class="list-al"><Input placeholder="请输入会员名称编号" v-model="searchData.keywords" /></div>
            <!-- <div class="list-al">选择产品：
                <Select transfer v-model="searchData.application_id"  clearable style="width:200px"  placeholder="请选择产品" >
                    <Option v-for="item in systemList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </div> -->
            <!-- <div class="list-al">状态：
                <Select v-model="searchData.status" @on-change="goClear" clearable style="width: 200px" placeholder="请选择订单状态">
                    <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.label }}</Option>
                </Select>
            </div> -->
            <div class="list-al">日期：
                <div class="r-find">
                    <DatePicker @on-change="changeTime" v-model="timeVal" type="daterange" placement="bottom-end"
                        placeholder="请选择截至日期" style="width: 240px"></DatePicker>
                </div>
            </div>
            <div class="list-al">
                <Button @click="searchList()"   style="background:#4877E8;color:#fff;border-color:#4877E8">搜索</Button>
            </div>
        </div>
        <div class="table-body">           
            <Table :columns="columnTable" :data="dataTable" :loading='loadingTab'>             
                <template slot-scope="{ row }" slot="coupon">
                    {{ row.coupon.name}}
                </template>     
                <!-- data.data.member.name -->
                <template slot-scope="{ row }" slot="goodsname">
                    {{ row.goods.name}}
                </template>    
                <template slot-scope="{ row }" slot="membername">
                    {{ row.member.name}}
                </template>                                      
                <template slot-scope="{row}" slot="action">
                    <div class=""  v-if="row.original_status == 1" >      
                        <Button type="text"  @click="subTemplete(row)" style="color:#4877E8">退单</Button>                 
                        <!-- <Button type="text" v-if="row.status == '1'||row.status == '0'||row.status == '2'" @click="editRow(row)" style="color:#4877E8">修改</Button>
                        <Button type="text" v-if="row.status == '1' " @click="subTemplete(row)" style="color:#4877E8">使用</Button>
                        <Button type="text" v-if="row.status == '3'" @click="subTemplete(row)" style="color:#4877E8">取消使用</Button> -->
                    </div>
                </template>
            </Table>
            <Page :total="total" :current="searchData.page" style="text-align:right;margin-top: 20px" show-elevator
                show-total @on-change="pageChange" :page-size="searchData.limit" />
        </div>       
    </div>
</template>

<script>
import { get_order_list,order_refund} from "@/api/index";
export default {
    data() {
        return {
            timer: '',
            submitData:{
                id:'',
                type: 3,
                status:'',
                application_id:'',
                title: '',
            },
            formTitle: 'add',
            loading: false,
            total: 0,
            timeVal: [],
            loadingTab: false,
            dataTable: [],         
            searchData: {                
                start_date: '',
                end_date: "",
                page: 1,
                type: 2,
                keywords: '',
                limit: 10
            },
            typeList: [
                { id: 0, label: '待审核' },
                { id: 1, label: '审核通过' },
                { id: 2, label: '审核失败 ' },
                { id: 3, label: '使用中' },
                { id: 4, label: '退款' },
            ],            
            total_num: 0,            
            systemList: [],
            columnTable: [                
            {
                    title: '序号',
                    key: 'id',
                    align: 'center',
                    width: 80
                },
                // data.data.coupon.name
                {
                    title: '套餐名称',
                    slot: 'goodsname',
                    align: 'center'
                }, 
                // data.data.member.name
                {
                    title: '订单金额',                    
                    key: 'money',
                    align: 'center'
                }, 
                {
                    title: '付款时间',
                    key: 'created_at',
                    align: 'center'
                }, 
                {
                    title: '会员',
                    slot: 'membername',
                    align: 'center'
                }, 
                {
                    title: '状态',
                    key: 'status',
                    align: 'center'
                },                
                {
                    title: '操作',
                    slot: 'action',
                    width: 320,
                    align: 'center'
                }
            ]
        }
    },
    created() {
        this.get_list()
    },
    methods: {       
        searchList() {
            this.searchData.page = 1
            this.get_list()
        },
        get_list() {
            this.loading = true
            this.loadingTab = true
            get_order_list(this.searchData).then(res => {
                this.dataTable = res.data.data
                this.total = res.data.total
                this.loading = false
                this.loadingTab = false
            }).catch(res => {
                this.$Message.error(res.msg)
                this.dataTable.push({id:1})
                this.loadingTab = false
                this.loading = false
            })
        },
        changeTime(e) {
            this.searchData.start_date = e[0]
            this.searchData.end_date = e[1]
        },
        pageChange(index) {
            this.searchData.page = index
            this.get_list()
        },
        subTemplete(e) {
            this.$Modal.confirm({
                title: '提示',
                content: '是否确认退单该订单',
                onOk: () => {             
                    order_refund({ id: e.id }).then(res => {
                        this.$Message.success(res.msg)
                        this.get_list()
                    }).catch(err => {
                        this.$Message.error(err.msg)
                        this.get_list()
                    })
                },
                onCancel: () => {
                }
            });
        },
        goClear(e){
            console.log('e',e)
            if(!(e || e == 0)){
                this.searchData.status = ''
            }
        },
        closeMod() {
            this.showPay = false
            clearInterval(this.timer)
        }        
    }


}
</script>

<style scoped lang="scss">
::v-deep .ivu-modal-header-inner {
    font-weight: 500 !important;
}

::v-deep .ivu-modal-body {
    padding: 16px 0px !important;
}

.table-body {
    width: 100%;
    height: 100%;
    background: #ffffff;
    margin: 20px 0px;
    padding: 10px 30px;
}

.cont-cla {
    width: 100%;
    height: 100%;
}


.search-list {
    width: 100%;
    padding: 20px 30px;
    padding-bottom: 20px;
    color: #545F78;
    background: #ffffff;
    font-family: PingFang SC;
    // margin-top: 20px;
    display: flex;
    line-height: 32px;
    word-break: keep-all;
    white-space: nowrap;
    flex-wrap: wrap;
}
.list-al {
    display: flex;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.flex-s {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 28%;
}

::v-deep .ivu-modal-header p {
    font-weight: 900
}

::v-deep .ivu-modal-header-inner {
    font-weight: 900
}

::v-deep .bottom-swip img {
    // width: 75%;
    // height: 75%;
    max-width: 100%;
    max-height: 100%;
    padding: 0px 16px // margin-left: 10%;
}
::v-deep.canshowcont {
    .ivu-modal-close {
        display: none;
    }
}
</style>

<style>
.demo-spin-icon-load {
    animation: ani-demo-spin 3s linear infinite;
}

@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.demo-spin-col {
    top: 80px;
    left: 80px;
    position: relative;
    /* // border: 1px solid #eee; */
}
</style>